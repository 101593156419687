@charset "UTF-8";
/* ---------------------------- wrapper */
.wrapper {
  padding-top: 69px;
  max-width: 750px;
  margin: 0 auto;
}

/* ---------------------------- header */
.pane-header {
  > .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    background: white;
    border-top: none;
    // box-shadow: 0 1px 2px rgba(51, 51, 51, 0.2);
    box-shadow: none;
    border-bottom: solid 1px #D9D9D9;
    padding: 10px 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
.block-header-logo {
  width: 90px !important;
  flex-basis:inherit;
  img {
    width: 100%;
    max-width: 100%;
  }
}
#block-hader__navBtn {
  width: 48px;
  height: 48px;
  margin: 0px;
  padding: 0 ;
  border: none;
  background-color: #fff;
  border: solid 1px #E5E5E5;
  border-radius: 100px;
}
.tcon-menu--xcross  {
  width: 48px;
  height: 48px;  
  .tcon-menu__lines {
    top: 21px;
    width: 16px;
    height: 2px;
    background-color: #000;
    margin-left: -1px;
    &::before,
    &::after{
      height: 2px;
      background-color: #000;
    }
    &::before {
      top: -6px;
    }
    &::after {
      top: 6px;
    }
  }  
  &.js_active {
    .tcon-menu__lines {
      &::before {
        top: -2px;
        left: 0;
      }
      &::after {
        top: 1px;
        left: 0;
      }
    }
  }
  .tcon-visuallyhidden{
    display: none;
  }
}
.block-headerMenu {
  padding: 0;
  z-index: 201;
  &--list {
    padding: 0;
    a {
      display: block;
      font-size: 12px;
      color: #000;
      font-weight: 500;
      text-decoration: none;
      line-height: 1.4;
      padding: 15px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border: none;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -2px;
        transform: rotate(45deg);
      }
    }
  }
}
.block-headerNav {
  &--item-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1;
    li {
      margin: 0 0 0 10px;
      font-size: 10px;
      font-weight: 400;
      &:first-child{
        margin: 0;
      }
    }
    a {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: #000;
      font-weight: 400;
      text-decoration: none;
      padding: 0px;
      position: relative;
      .icon {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
        &.icon-member-regist {
          width: 21px;
        }
      }
      &:hover {
        opacity: 0.6;
      }
    }
    .block-headernav--cart-count {
      display: none;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      background: #E60012;
      color: #fff;
      font-size: 10px;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      top: -8px;
    }
  }
}


/* ---------------------------- contents */
.pane-modal,
.pane-itemList {
  display: none;
  position: relative;
}



/* ---------------------------- footer */
.pane-footer {
  padding: 0px 0 20px;
  background-color: #f5f5f5;
}
.block-footerBanner {
  margin : 0 auto;
  padding: 15px 0 15px;
  background-color: #fff;
  &-list{
    padding: 0 15px;
    li {
      position: relative;
      margin-bottom: 10px;
      border-radius: 8px;
      line-height: 1;
      &:last-child {
        margin-bottom: 0px;
      }
      a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        padding: 30px 0 160px;
        &:hover {
          opacity: 0.6;
        }
      }  
    }
  }
  &-title{
    font-size:21px;
    font-weight:700;
    text-align: center;
  }
  &-link{
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    font-weight:700;
    color: #3A8CA6;
    span{
      &.link-arrow-right{
        &::after {
          border-color: #3A8CA6;
        }
      }
    }
  }
  &-img{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  &-kodawari{
    background-color: #F2F9EF;
    .block-footerBanner-img {
      height: 150px;
      img {
        height: 170px;
      }
    }
  }
  &-teiki{
    background-color: #FBF9D1;
    .block-footerBanner-img {
      height: 150px;
      margin-left:20px;
    }
  }
  &-flow{
    background-color: #FCF2D8;
    .block-footerBanner-img {
      height: 150px;
    }
  }
  &-guide{
    background-color: #EFFAFA;
    .block-footerBanner-img {
      height: 150px;
    }
  }
  &-faq{
    background-color: #FFF6F6;
    .block-footerBanner-img {
      height: 150px;
    }
  }
}
.block-footerNav {
  margin-top: 20px;
  li {
    border-top: solid 0px #d1d1d1;
    &:last-child {
      border-bottom: solid 0px #d1d1d1;
    }
  }
  a {
    position: relative;
    padding: 10px 10px;
    color: #000;
    &::after {
      background-image: none;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border: none;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -2px;
      transform: rotate(45deg);
    }
  }
}

.block-copyright {
  margin-top: 20px;
  padding-top: 15px;
  border-top: solid 1px #d1d1d1;
  font-size: 10px;
  color: #929292;
}
.block-ftLogo {
  margin: 15px 0;
}
.footer-tel {
  display: block;
  font-size: 12px;
  &-num {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;  
  }
}
/* ---------------------------- fixd サイドメニュー */
.pane-side-fixedNav {
  position:fixed;
  top:15%;
  right:0;
  z-index: 5;
  transition: all .5s;
  opacity: 1;
  .is_noscroll & {
    opacity: 0;
    right:-30px;
  }
  .is_endscroll & {
    opacity: 0;
    right:-30px;
  }
  &-list{
    li{
      margin-bottom: 4px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 130px;
      color:#fff;
      background-color:#000;
      text-decoration:none;
      font-size: 13px;
      font-weight: 700;
      writing-mode: vertical-rl;
      border-radius: 8px 0 0 8px;
      letter-spacing: 0.1em;
    }
    .request {
      a {
        background-color: #E60012;
        &:hover{
          background-color: #BE291E;
        }  
      }
    }
    .about{
      a {
        background-color:#000;
        &:hover{
          background-color: #666;
        }  
      }
    }
  }
}

// ヘッダースクロールで状態変更
.is_scroll {
  .block-page-nav {
  }
}